<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.agencyWalkinPeriodRuleForm"
    />
    <h3 class="pb-2" v-if="!addingRule">
      Agency Walk-In Reservation
      <hr />
    </h3>
    <button
      class="btn btn-primary mb-3"
      v-if="!addingRule"
      @click="addingRule = true"
    >
      Add New Rule
      <i class="fa fa-plus-circle" />
    </button>
    <div class="card" v-if="addingRule">
      <div class="card-header">
        <div class="card-title mb-0">Agency Walk-In Reservation</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="agencyWalkinPeriodRuleForm">
          <form @submit.prevent="onSubmit">
            <AdminCommonBusinessRuleFields
              :rule="agencyWalkinPeriodRule"
              :referenceId="referenceId"
              :fieldOptionOverrides="fieldOptionOverrides"
              :getAllLocations="true"
            />
            <hr />
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required"
                  name="Walk-In Start Length"
                  id="startDateLength"
                  placeholder="Enter Walk-In Start Length"
                  v-model="agencyWalkinPeriodRule.startDateLength"
                />
                <small class="form-text text-muted mb-2">
                  The starting unit of time that applies to an admin walk-in
                  reservation.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Unit"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="startDateUnitId"
                  >
                    Unit
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="startDateUnitId"
                    v-model="agencyWalkinPeriodRule.startDateUnitId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(choice, index) in unitChoices"
                      :key="index"
                      :value="choice.id"
                      >{{ choice.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Units of time to apply to length.</small
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required"
                  name="Walk-In End Length"
                  id="endDateLength"
                  placeholder="Enter Walk-In End Length"
                  v-model="agencyWalkinPeriodRule.endDateLength"
                />
                <small class="form-text text-muted mb-2">
                  The ending unit of time that applies to an admin walk-in
                  reservation.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Unit"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="endDateUnitId"
                  >
                    Unit
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="endDateUnitId"
                    v-model="agencyWalkinPeriodRule.endDateUnitId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(choice, index) in unitChoices"
                      :key="index"
                      :value="choice.id"
                      >{{ choice.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Units of time to apply to length.</small
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required"
                  name="Walk-In Reservation Max Length"
                  id="length"
                  placeholder="Enter Walk-In Reservation Max length"
                  v-model="agencyWalkinPeriodRule.length"
                />
                <small class="form-text text-muted mb-2">
                  The maximum length of time that applies to an admin walk-in
                  reservation.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Unit"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="unitId"
                  >
                    Unit
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="unitId"
                    v-model="agencyWalkinPeriodRule.unitId"
                    v-bind="ariaInput"
                    :disabled="true"
                  >
                    <option
                      v-for="(choice, index) in unitChoices"
                      :key="index"
                      :value="choice.id"
                      >{{ choice.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Units of time to apply to length.</small
                >
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-3">
              Submit
            </button>
            <button
              class="btn btn-cancel btn-lg mt-3 ml-1"
              @click="addingRule = false"
              type="button"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <AdminBusinessRuleTable
      :tableData="tableData"
      @viewRule="viewRule"
      @editRule="editRule"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields";
import AdminBusinessRuleTable from "@/components/admin/AdminBusinessRuleTable";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import TextInput from "@/validation/TextInput.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";

export default {
  name: "AdminAgencyWalkinPeriod",
  title: "Admin - Agency Walk-In Reservation",
  components: {
    ValidationObserver,
    ValidationProvider,
    AdminCommonBusinessRuleFields,
    AdminBusinessRuleTable,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      agencyWalkinPeriodRule: {
        name: "Agency Walk-In Period",
        isActive: true,
        productId: "",
        productTypeId: "",
        loopId: "",
        seasonTypeId: "",
        salesChannelId: "2",
        paymentTypeId: "",
        unitId: "1",
        startDateUnitId: "1",
        endDateUnitId: "1",
        locations: []
      },
      tableData: [],
      unitChoices: [],
      addingRule: false,
      booleanChoices: [
        { key: "Yes", value: true },
        { key: "No", value: false }
      ],
      errors: [],
      fieldOptionOverrides: [
        { optionsArray: "productClasses", optionNames: ["All"] },
        { optionsArray: "salesChannels", optionNames: ["Agency"] }
      ]
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    referenceId() {
      return `H${(this.tableData.length + 1 + "").padStart(3, "0")}`;
    }
  },
  methods: {
    async initialize() {
      const dataService = new AdminLookupService(this.tenantId);
      dataService.getAllUnits().then(response => {
        this.unitChoices = response;
      });
      this.loadData();
    },
    async loadData() {
      const businessRuleService = new AdminBusinessRuleService(this.tenantId);
      businessRuleService.getAllAgencyWalkInPeriod().then(response => {
        this.tableData = response.data?.map((x, index) => {
          return { ...x, referenceId: `H${(index + 1 + "").padStart(3, "0")}` };
        });
      });
    },
    async onSubmit() {
      this.$refs.agencyWalkinPeriodRuleForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.agencyWalkinPeriodRuleForm.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.agencyWalkinPeriodRuleForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          //Map to request object
          var request = {
            isActive: this.agencyWalkinPeriodRule.isActive,
            productId: this.agencyWalkinPeriodRule.productId,
            productTypeId: this.agencyWalkinPeriodRule.productTypeId,
            loopId: this.agencyWalkinPeriodRule.loopId,
            salesChannelId: this.agencyWalkinPeriodRule.salesChannelId,
            seasonTypeId: this.agencyWalkinPeriodRule.seasonTypeId,
            paymentTypeId: this.agencyWalkinPeriodRule.seasonTypeId,
            comments: this.agencyWalkinPeriodRule.comments,
            startDate: this.agencyWalkinPeriodRule.startDate,
            endDate: this.agencyWalkinPeriodRule.endDate,
            length: this.agencyWalkinPeriodRule.length,
            unitId: this.agencyWalkinPeriodRule.unitId,
            startDateLength: this.agencyWalkinPeriodRule.startDateLength,
            startDateUnitId: this.agencyWalkinPeriodRule.startDateUnitId,
            endDateLength: this.agencyWalkinPeriodRule.endDateLength,
            endDateUnitId: this.agencyWalkinPeriodRule.endDateUnitId,
            validationMessage: this.agencyWalkinPeriodRule.validationMessage
          };
          if (this.agencyWalkinPeriodRule.locations) {
            request.locationIds = this.agencyWalkinPeriodRule.locations.map(
              n => n.id
            );
          }
          const businessRuleService = new AdminBusinessRuleService(
            this.tenantId
          );
          var response = await businessRuleService.addAgencyWalkIn(request);
          if (response.statusCode == "Success") {
            //Reload data for grid
            this.loadData();
            this.agencyWalkinPeriodRule = {
              name: "Agency Walk-In Period",
              isActive: true,
              productId: "",
              productTypeId: "",
              locations: [],
              loopId: "",
              seasonTypeId: "",
              salesChannelId: "2",
              paymentTypeId: "",
              unitId: "1",
              startDateUnitId: "1",
              endDateUnitId: "1"
            };
            this.addingRule = false;
            window.scrollTo(0, 0);
          }
        }
      });
    },
    viewRule(item) {
      this.$router
        .push(
          `/admin/brm/agency-walkin-period/${item.id}?refId=${item.referenceId}&v=true`
        )
        .catch(() => {});
    },
    editRule(item) {
      this.$router
        .push(
          `/admin/brm/agency-walkin-period/${item.id}?refId=${item.referenceId}&v=false`
        )
        .catch(() => {});
    }
  },
  created() {
    this.initialize();
  }
};
</script>
